
//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed var(--#{$prefix}border-color);
  background:  var(--#{$prefix}white);
  border-radius: 6px;

  .dz-message {
    font-size: 16px;
    width: 100%;
  }
}

.previewDrop{
  border: none !important;
}

.dz-message {
  img{
    object-fit: cover !important;
  }
}

