@media (max-width: 767px) {
  .mainText {
    margin: 1rem 0 !important;
  }
  .mapRegion .inner {
    margin-left: 0 !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mainText {
    // margin: 0 !important;
    // margin: auto !important;
  }
}

@media only screen and (max-width: 768px) {
  .setppers {
    min-width: 70px !important;
  }
  .new-login-form{
    height: 100% !important;
  }
}
