.steperSection {
  padding: 1em 0;
  h3 {
    text-align: center;
    font-weight: 600;
    margin-bottom: 1em;
    font-size: 20px;
  }
  .stepnum {
    text-align: center;
    justify-content: center;
    position: relative;
    p {
      padding-top: 0.5em;
      text-transform: capitalize;
    }
    span {
      background-color: #e5e5e5;
      padding: 5px 10px;
      border-radius: 100em;
      color: #000;
    }
  }
}
.actives .active {
  background-color: $lightBlue !important;
  padding: 5px 10px;
  border-radius: 100em;
  color: #fff !important;
}
.line::before {
  content: "";
  width: 8em;
  position: absolute;
  right: -52%;
  border-bottom: 2px dashed #e5e5e5;
  top: 20%;
}

.actives .activeLine::before {
  content: "";
  width: 8em;
  position: absolute;
  right: -52%;
  border-bottom: 2px dashed $lightBlue !important;
  top: 20%;
}
