//
// authentication.scss
//
$blueHead: #0022a0;

// authentication

.authentication-bg {
  background-color: $auth-bg;
  .auth-logo {
    .logo {
      margin: 0px auto;
    }
  }
}

///Auth Register Page////

.body {
  background: #eeeeee !important;
}

.boxCenter {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  // background-color: rgba(91, 140, 232, 0.25);
}

.hello {
  align-items: start !important;
  .hello1 {
    padding: 0 !important;
    img {
      height: 38em !important;
      width: 100% !important;
    }
  }
  .hello2 {
    padding: 0 !important;
    position: relative;
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    .card {
      height: 38em !important;
      width: 100% !important;
      margin-bottom: 0 !important ;
      border-top-right-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      box-shadow: 1px 2px 5px #b4b5ba;
      .cardInternal {
        // padding-bottom: 44px !important;
        .cornerImage {
          position: absolute !important;
          top: 0 !important;
          right: 0 !important;
          height: 10em !important;
          border-radius: 13px !important;
        }
      }
    }
  }
}

.mobLabelPart {
  height: 4.5em !important;
  // border: 2px solid red !important;
}

.invalid-feedback {
  font-size: 10px !important;
  margin-top: 0 !important;
}

.formCaptcha {
  border: 1px solid #fff !important;
}

.createButton {
  width: 100% !important;
  background: $blueHead !important;
  font-weight: 700 !important;
  padding: 9.5px !important;
  font-size: 12px !important;
}

.logButton {
  color: $blueHead !important;
  font-weight: 600 !important;
}

.logText {
  color: #2d2d2d !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.AuthHead {
  color: $blueHead !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  margin-bottom: 5px;
}

.authSubHead {
  color: #000000 !important;
  font-weight: 500;
  font-size: 14px !important;
  margin-bottom: 5px !important;
}

.formHead {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0 !important;
}

.formImp {
  color: red;
}

.register-logo {
  height: 2.5em;
  margin-bottom: 9px;
}

.termsCheck {
  font-size: 10px;
  color: #989898 !important;
  font-weight: 300 !important;
}

.form-control::placeholder {
  color: #989898 !important;
  font-size: 12px;
}

// signin card title

.signin-other-title {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: $border-color;
    top: 10px;
  }

  .title {
    display: inline-block;
    position: relative;
    z-index: 9;
    background-color: $card-bg;
    padding: 2px 16px;
  }
}

// auth carousel

.auth-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: $card-bg;
        }
        &.active {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}

/////Thankyou Page //////

.shadowBox {
  border-radius: 15px;
  padding: 4rem 0;
  box-shadow: 1px 2px 15px #cdcdcd;
  position: relative;

  .thanksLogoColumn {
    text-align: center;
  }

  .thanksContent {
    text-align: center;
    margin: 2rem 0;
    .thanksHead {
      font-size: 20px !important;
      font-weight: 700;
      color: #3d3d3d !important;
    }
    .thanksSubHead {
      font-size: 12px;
      color: #989898;
      margin: 1rem 0 !important;
    }
    .socialIcons {
      display: flex;
      justify-content: center;
      margin: 2rem 0;
      .socialMedia {
        margin: 0 0.5rem;
        height: 1.7rem;
      }
    }
  }

  .proceedSection {
    margin-top: 3rem !important;
    text-align: center;
    .proceedButton {
      background-color: $blueHead;
      color: #fff;
      font-weight: 700;
      font-size: 13px;
      width: 16% !important;
      border: 1px solid $blueHead;
      padding: 7px 12px;
    }
  }

  .thanksCornerImage {
    position: absolute;
    top: -64px;
    right: 0;
    border-radius: 15px;
  }
}

.thanksPageTopLogo {
  display: flex;
  justify-content: center;
  margin: 2rem 0 2rem 0;
}

$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .originLogo {
    margin: 2rem 0;
  }
  .new-login-form{
    display: block !important;
    height: 100% !important;
  }
  .boxCenter {
    position: inherit !important;
    transform: none !important;
    margin-top: 1em !important;
  }
  .shadowBox .thanksCornerImage {
    top: -96px;
  }

  .mobTopLogo {
    height: 2em !important;
  }

  .mobLabelPart {
    width: 100%;
    margin: 0.5rem 0;
  }

  .hello .hello2 .card {
    height: 100% !important;
    margin-bottom: 2em !important;
    width: 95% !important;
    // margin: auto !important;
    border-bottom-left-radius: 15px !important;
    border-top-left-radius: 15px !important;
  }

  .cardInternal {
    padding: 0.5rem !important;
  }

  .button-column .createButton {
    // padding: 4.5px !important;
    font-size: 10px !important ;
  }

  .shadowBox {
    padding: 6rem 0 !important;
    margin-bottom: 3rem !important;
    width: 100%;
    margin: auto;

    .thanksContent {
      margin: 2rem 0 !important;
      .thanksHead {
        font-size: 14px !important;
      }
      .thanksSubHead {
        font-size: 11px !important;
        margin: 0 !important;
      }
      .socialIcons {
        margin: 1rem 0 !important;
        .socialMedia {
          height: 1.4rem !important;
        }
      }
    }

    .proceedSection {
      margin-top: 2rem !important;
      .proceedButton {
        font-size: 10px;
        width: 40% !important;
        padding: 7px;
      }
    }
  }

  .hello {
    align-items: start !important;
    .hello1 {
      display: none !important;
    }
    .hello2 {
      padding: 0 !important;
      .card {
        .cardInternal {
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .outerCard {
    .outerCardBody {
      .nav-item {
        span {
          padding: 4px !important;
          font-size: 10px !important;
        }
      }
      .innerCard {
        padding: 0.85rem 1rem !important;
      }
    }
  }

  .outerCard .outerCardBody .innerCard {
    padding: 0.85rem 1rem !important;
  }
}

/////// Captcha //////
.formCaptcha .rc-anchor-logo-portrait {
  margin: 12px 0 0 0 !important;
}

.formCaptcha .rc-anchor-logo-img {
  object-fit: contain !important;
}
.formCaptcha .rc-anchor-logo-img-portrait {
  height: 25px !important;
  width: 25px !important;
}

/////Login Page/////////
.loginFormLabel {
  height: 5em ;
  .otpSetup {
    div {
      justify-content: center !important;
      input {
        width: 2em !important;
        margin: 0 1rem;
        border: none;
        border-bottom: 1px solid #cdcdcd;
        padding: 5px;
      }
      span {
        display: none;
      }
    }
  }
}

.new-login-form {
  display: flex;
}

.button-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .createButton {
    margin-top: 0.5rem !important;
    padding: 9.5px !important;
    z-index: 9999 !important;
    position: relative !important;
  }
  .timer {
    margin-top: 1.5rem !important;
  }
}

.otpCheck {
  font-size: 12px;
  color: #989898;
  font-weight: 400;
}

.resendText {
  font-size: 12px;
  color: #989898;
  font-weight: 400;
}

.resend-span-body {
  color: #0022a0 !important;
  font-weight: 900;
  font-size: small;
  padding: 4px 10px !important;
  // -webkit-box-shadow: inset 0 0 6px rgba(220 216 216 / 50%) !important;
}

.resend-span-body:hover {
  cursor: pointer !important;
}

.loginHalfBox {
  padding: 0;
  .card {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.outerCard {
  border: none !important;
  box-shadow: 1px 2px 10px #c7c5c5 !important;
  height: 38em !important;
  margin-bottom: 0 !important;
  @media (max-width: 767px) {
    width: 95% !important;
    margin: auto !important;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100% !important;
  }
  .outerCardBody {
    padding: 0 !important;
    .nav-item {
      width: 50%;
      text-align: center;
      background-color: #f4f4f4;
      -webkit-box-shadow: inset(0 0 6px rgba(233, 15, 15, 0.5)) !important;
      color: #989898 !important;
      border-top-right-radius: 15px !important;
      span {
        padding: 8px;
        font-size: 14px;
      }
    }
    .innerCard {
      padding: 0.85rem 2rem !important;
      margin-bottom: 0 !important;
      box-shadow: none !important;
      border: 1px solid #fff !important;
      .innerCardBody {
        padding: 0 !important;
        margin-bottom: 0 !important;
        border: none !important;
      }
    }
  }
}

.outerCard .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: $blueHead;
  color: #fff !important;
  border: none !important;
  border-top-right-radius: 10px;
}

.loginLogoSection {
  height: 1.3em !important;
}

.tab-content {
  position: relative !important;
}
.LoginCornerImage {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 15px;
  height: 10em !important;
}

.css-1jqq78o-placeholder {
  font-size: 12px !important;
}

///// OTP Verification Page //////

.OtpCard {
  position: relative;
  .OtpCardBody {
    padding: 3rem 2em !important;
    height: 36.2em;
    .OtpCardLogo {
      height: 2em !important;
    }
    .otpSection {
      text-align: center;
      margin: 2rem 0;
      .OtpPart {
        margin: 1.5rem 0;
        .otpText {
          color: #989898;
          font-weight: 400;
          font-size: 12px;
          .otpNumber {
            color: #3d3d3d;
            font-weight: 900;
            font-size: 12px;
          }
        }
        .otpSetup {
          div {
            justify-content: center !important;
            input {
              width: 2em !important;
              margin: 0 1rem;
              border: none;
              border-bottom: 1px solid #cdcdcd;
              //   box-shadow: 0px 1px 1px #cdcdcd;
              padding: 5px;
            }
            span {
              display: none;
            }
          }
        }
      }
    }

    .OtpEditText {
      text-align: center;
      .EditText {
        font-size: 12px;
        font-weight: 700;
        text-decoration: underline;
        color: 0022A0 !important;
      }
    }
  }

  .OtpCornerImage {
    border-radius: 15px;
    position: absolute;
    top: 0;
    right: 0;
  }
}


.registerselect{
  .select2-selectionsssss__control{
    padding: 0 !important;
  }
}

.capctha{
  margin-top: 1em !important;
}