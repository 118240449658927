* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.accordion {
  margin-bottom: 10px;
}

.accordion-header {
  margin: 5px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-header .button-container {
  display: flex;
  justify-content: flex-end;
}

.accordion-header .button-container .accordion-title {
  background-color: white;
  outline: none !important;
  border: solid 2px white;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
  color: black;
  width: 100% !important;
  /* font-weight: 600; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordion-header .button-container .accordion-title::placeholder {
  color: black;
}


.accordion-header .button-container:nth-child(1) {
  width: 92%;
  border-radius: 8px;
  padding: 10px;
  color: #f5f5f5 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-align: left;
  border: none;
  cursor: pointer;
  border: none !important;
  outline: none !important;
}

.accordion-header .button-container:nth-child(2) {
  /* width: 8%; */
}

.accordion-header .button-container .dynamic-accordion-button {
  /* padding: 10px; */
  color: #f5f5f5 !important;
  /* padding-top: 8px !important; */
  /* padding-bottom: 8px !important; */
  text-align: left;
  border: none;
  cursor: pointer;
  border: none !important;
  outline: none !important;
}

.accordion-header .button-container:nth-child(1) .dynamic-accordion-button {
  border-radius: 8px;
  text-align: center;
  width: 100%;
}

.accordion-header .button-container:nth-child(2) .dynamic-accordion-button {
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.dynamic-accordion-collapse {
  width: 92%;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.dynamic-accordion-collapse .accordion-body {
  margin: 5px 0px 10px 0px;
  padding: 10px 20px 30px 20px !important;
}

.accordion-content {
  padding-left: 16px !important;
}

/* Full-screen overlay */
.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Container for the loader */
.loader-container {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Loader circle */
.loader {
  width: 80px;
  height: 80px;
  border: 5px solid #f3f3f3;
  border-top: 4px solid #0022A0;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.productlist-icons .edit-icon-productlist {
  padding: 7px;
  color: #000;
}

.productlist-icons .delete-icon-productlist {
  padding: 7px;
  color: red;
}

.productImage-table {
  height: 5em;
  width: 100%;
  object-fit: contain;
}

.custom-accordion .accordion {
  margin-bottom: 0 !important;
}

.custom-accordion .accordion-item {
  border: none !important;
}

.custom-accordion .accordion-header>button {
  padding: 10px;
}

.custom-accordion li {
  margin: 8px 0;
}

.custom-accordion .accordion-body {
  padding-left: 0 !important;
}

.custom-accordion .accordion-button:not(.collapsed) {
  background: #ffffff !important;
}

.cateModal {
  height: 19em !important;
}

.pborder {
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  padding: 1em 0;
}

.bottomBorder {
  border-bottom: 1px solid #cdcdcd;
  margin-bottom: 1em;
}

.remveimg {
  display: none;
}

.background-acc {
  background-color: red;
}

.innertempcard {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 4.7em;

}

.tempCard {
  border-radius: 10px;
  border: 1px solid #D3D3D3;
}

.previewImg {
  border-radius: 10px;
  border: 1px solid #D3D3D3;
  position: relative;
}

.previewImg img {
  width: 100%;
}

.tick-mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50%;
  font-size: 24px;
  color: #f3f3f3;
  /* Adjust right/left and other styles as needed */
}

.tempTextContainer {
  width: 100%; /* Set the width of the container as needed */
}

.tempText {
  background-color: #F4F4F4;
  text-align: center;
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tempText p {
  margin-bottom: 0 !important;
  padding: 10px;
  width: 75%;
}

.iconContainer {
  width: 25%; /* Set the width of the icon container as needed */
  display: flex;
  justify-content: flex-end; /* Align the icon to the right within the container */
}

.product-img img {
  height: 16em;
  object-fit: cover;
  width: 100%;
}

.product-content h5 {
  height: 2.5em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-detail{
  position: sticky;
  top: 20em;
}

.mainHead{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.mainHead h5{
  margin-bottom: 0 !important;
}

.img-fluid-product{
  padding: 1em;
  max-width: 100%;
  height: auto;
}

.productCard{
  height: 25.5em !important;
}