@media (max-width: 768px) {
  .mobile{
    flex-direction: row-reverse;
  }
  .hide-bullet-catalog{
    padding-left: 0 !important;
  }
  .mapSection{
    margin-top:2em !important;
  }
  .capctha{
    margin-top: 1em;
  }
  .hello .hello2 .card {
    height: 100% !important;
    width: 95% !important;
    margin: auto !important;
    border-bottom-left-radius: 15px !important;
    border-top-left-radius: 15px !important;
  }
  .networkBtn {
    position: inherit !important;
    right: 0;
    bottom: 0px !important;
    width: 13em;
    text-align: center;
    background: $mainBlue;
    justify-content: center;
    color: #fff;
  }
  .main-stepper{
    display: none !important;
  }
  .btn-css{
    text-align: center !important;
  }
  .editDataShow .btn{
    margin-right: 0.3em !important;
  }
  .webFilter {
    display: none;
  }
  .mobFilters{
    display: block !important;
  }
  .mainSelect {
    .select2-selectionsssss__control {
      // padding: 0px 0 !important;
      padding: 0.25em 0 !important;
      margin-top: 0em !important;
    }
  }
  .mobFilter {
    display: block !important;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 80%;
    height: 100vh;
    .card-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }
  }
  // .productlist-buttons{
  //   display: grid !important;
  // }
  .productlist-header {
    display: inline-block !important;
    width: 100%;
  }
  .fontSetting {
    margin: 0 !important;
    margin-right: 1em !important;
    margin-top: 1em !important;
  }

  // .select2-selectionsssss__control{
  //   padding: 0.22rem 0.5rem !important;
  // }
  .mobRespo {
    width: 20% !important;
    .stepnum {
      p {
        font-size: 11px;
      }
    }
    .line::before {
      content: "";
      width: 3em;
      position: absolute;
      right: -59%;
      border-bottom: 2px dashed #e5e5e5;
      top: 20%;
    }
  }
  .location {
    .dt-bootstrap4 {
      div[data-test="datatable-entries"] {
        width: 50%;
      }
      div[data-test="datatable-search"] {
        width: 50%;
        margin-bottom: 9px;
      }
      div[data-test="datatable-info"] {
        width: 50%;
        margin-top: 1em;
      }
      div[data-test="datatable-pagination"] {
        width: 50%;
        margin-top: 1em;
      }
    }
    .addNewBtn {
      text-align: center;
      display: inline-block !important;
      .fontSetting {
        font-size: 12px !important;
        margin-bottom: 1em !important;
      }
    }
    .formatOptions {
      text-align: center;
      margin-bottom: 1em;
    }
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .mobRespo {
    .line::before {
      content: "";
      width: 6em;
      position: absolute;
      right: -59%;
      border-bottom: 2px dashed #e5e5e5;
      top: 20%;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1700px) {
  .mobRespo {
    .line::before {
      content: "";
      width: 9em !important;
      position: absolute;
      right: -59%;
      border-bottom: 2px dashed #e5e5e5;
      top: 20%;
    }
  }
}
