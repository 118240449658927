.location{
    .mainHead{
        text-align: center;
        h4{
            font-weight: 600;
        }
    }
    .addNewBtn{
        margin-bottom: 1em;
        text-align: right;
    }
}

.HeadingTop{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    h4{
        font-weight: 600;
    }
}
.formatOptions{
    text-align: right;
}