.network-engage .tempText {
    display: flex;
    align-items: flex-start;
  }
  
.network-engage .tempText p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px; /* Adjust as needed */
    align-items: flex-start;
    text-align: left;
  }

.network-engage .previewImg{

}

.network-engage .tempCard{
    cursor: pointer;
}
  
  .iconContainer {
    margin-left: auto;
    margin-top: 3px; /* Align icon to the right */
  }
 