$red_accordion: #f75e5e;
$green_accordion: #a4f87d;
$yellow_accordion: #d6de42;

.dashCard {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 2em 1.5em;

  h2 {
    font-size: 18px;
    font-weight: $font-weight-bold;
  }

  p {
    margin-bottom: 0px;
  }

  span {
    color: $mainBlue;
  }
}

.netTab {
  .expadd {
    padding: 2em 0;
  }

  margin-top: 1.5em;

  .cardbody {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 2em 1.5em;
    // height: 27em;
    position: relative;

    @media (max-width: 768px) {
      height: 100%;
    }
  }

  .netImage {
    img {
      width: 100%;

      @media (min-width: 768px) and (max-width: 1024px) {
        height: 20em;
        object-fit: contain;
      }
    }
  }

  .netContent {
    padding: 1em 0;

    h4 {
      color: $gray-700;
      font-weight: $font-weight-bold;
      font-size: 16px;
      padding-left: 1em;
    }

    .netFirst::before {
      content: "";
      height: 1px;
      width: 6em;
      position: absolute;
      left: -26px;
      top: 50%;
      background-color: rgba($dBlue, 0.2);
      transform: rotate(90deg);
    }

    .before::before {
      content: "";
      height: 1px;
      width: 14.5em;
      position: absolute;
      left: -87px;
      top: 50%;
      background-color: rgba(1, 28, 128, 0.2);
      transform: rotate(90deg);
    }

    .after::before {
      content: "";
      height: 1px;
      width: 11.5em;
      position: absolute;
      left: -65px;
      top: 50%;
      background-color: rgba(1, 28, 128, 0.2);
      transform: rotate(90deg);
    }

    .before {
      margin-bottom: 0;
    }

    .after {
      margin-bottom: 0;
    }

    .netFirst {
      position: relative;

      ul {
        margin-bottom: 4px;
      }

      li {
        list-style: circle;
        color: $black;
        padding: 3px 0;
      }

      li::marker {
        color: $mainBlue;
      }
    }

    .netSecond {
      margin-left: 2em;
      margin-bottom: 0;

      li::marker {
        color: $mainBlue;
      }

      li {
        list-style: disc;
        color: $black;
      }
    }
  }
}

.saveBtn {
  text-align: center;

  .btn {
    background-color: $mainColor;
    color: $white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-right: 16px;
    // margin-top: 2em;
    text-transform: uppercase;
  }
}

.profile {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 1.5em 1.5em;

  .profileImageSection {
    h5 {
      font-weight: 600;
      margin-bottom: 1em;
    }
  }

  .profileImage {
    width: 10em;
    height: 10em;
    background-color: #eeeeee;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100%;

    @media (max-width: 767px) {
      height: 10em !important;
      width: 10em !important;
      margin: auto;
    }

    @media (min-width: 767px) and (max-width: 1024px) {
      height: 10em !important;
      width: 10em !important;
      margin: auto;
    }

    h5 {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 100px;
      font-weight: 600;
      margin-bottom: 0;
    }

    .edit {
      position: absolute;
      right: 15px;
      bottom: 0;
      width: 2em;
    }
  }

  .form-label {
    font-size: 14px;
    font-weight: $font-weight-bold;
    color: #000;
  }

  .form-control::placeholder {
    font-size: 14px;
    color: $black;
  }
}

.organized {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 2em 1.5em;

  .form-control::placeholder {
    font-size: 14px;
    color: $black;
  }

  .form-label {
    font-size: 14px;
    font-weight: $font-weight-bold;
  }

  .dropzone {
    background: #ffffff;
    // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: none !important;
    min-height: 100%;
    width: 100%;
    border: 1px solid #ccc !important;

    @media (max-width: 768px) {
      width: 100%;
    }

    .d-content {
      padding: 0em 0em 1em 0em !important;

      span {
        font-size: 14px;
      }

      h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0px !important;
      }
    }
  }
}

.guide {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 2em 1.5em;

  .guideContent {
    h4 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 1em;
    }

    .selectOne {
      display: flex;

      h5 {
        text-align: center;
        font-size: 12px;
        padding: 4px;
        color: $white;
      }

      .numSuccess {
        background-color: $mainGreen;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
      }

      .numPending {
        background-color: $lightBlue;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
      }

      .num {
        background-color: $gray-500;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
      }

      .textOne {
        margin-left: 1em;
      }
    }

    .resumeBtn {
      .btn {
        background-color: $mainGreen !important;
        color: $white;
      }
    }
  }
}

.sideSection {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 1em;

  @media (max-width: 767px) {
    margin-top: 1em;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    display: flex;
    margin-top: 1rem;
  }

  .accordian {
    margin-top: 1em;

    @media (min-width: 768px) and (max-width: 1024px) {
      width: 100%;
      margin-top: 0;
    }

    .accordion-item {
      border: none;
      border-bottom: 1px solid #f6f6f6;
    }
  }

  .sideContent {
    img {
      width: 100%;

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 90%;
      }
    }
  }
}

.progress {
  width: $size;
  height: $size;
  line-height: $size;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  margin-top: 2rem;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
  }

  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .progress-left {
    left: 0;
  }

  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $border-color-fill;
  }

  .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: ($size/2);
    border-bottom-right-radius: ($size/2);
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }

  .progress-right {
    right: 0;

    .progress-bar {
      left: -100%;
      border-top-left-radius: ($size/2);
      border-bottom-left-radius: ($size/2);
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }

  .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 22px;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    //font-family: $work-sans;
    font-weight: 300;

    div {
      color: $mainBlue;
      font-weight: 700;
    }

    span {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

.custom-pen-design {
  border: 1px solid #000 !important;
  color: #000 !important;
  padding: 0.5rem !important;
  border-radius: 50% !important;
  background-color: aliceblue !important;
  position: absolute !important;
  top: 110px !important;
  right: 0 !important;
  transform: rotateZ("270deg") !important;
}

.d-content h4 {
  font-size: 14px !important;
}

.d-content span {
  font-size: 13px !important;
}

.dropzone {
  min-height: 150px !important;
  margin-bottom: 10px !important;
}

.modal-content {
  padding: 1rem !important;
}

@for $i from 1 through $howManySteps {
  $stepName: ($i * (100 / $howManySteps));

  //animation only the left side if below 50%
  @if $i <=($howManySteps/2) {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        animation: loading-#{$i} $animationTime linear forwards;
      }

      .progress-left .progress-bar {
        animation: 0;
      }
    }
  }

  //animation only the right side if above 50%
  @if $i >($howManySteps/2) {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        animation: loading-#{($howManySteps/2)}
          $animationTime
          linear
          forwards; //set the animation to longest animation
      }

      .progress-left .progress-bar {
        animation: loading-#{$i -
          ($howManySteps/2)}
          $animationTime
          linear
          forwards
          $animationTime;
      }
    }
  }
}

//animation
@for $i from 1 through ($howManySteps/2) {
  $degrees: (180/ ($howManySteps/2));
  $degrees: ($degrees * $i);

  @keyframes loading-#{$i} {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate($degrees);
      transform: rotate(#{$degrees}deg);
    }
  }
}

//additional styling
.progress {
  margin-bottom: 1em;
}

.division {
  margin-bottom: 1em;

  .btn-danger {
    display: none;
  }

  .Divisionhead {
    display: flex;

    h5 {
      margin-bottom: 0px !important;
    }

    img {
      width: 1.5em;
      height: 1.5em;
      margin-left: 1em;
      margin-bottom: 10px;
    }
  }

  .editDataShow {
    // margin-top: 1em;
    // border: 1px solid #cdcdcd;
    border-radius: 5px;
    padding: 3px;

    @media (max-width: 767px) {
      display: flex !important;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      display: flex !important;
    }

    .fa-trash {
      color: red;
    }

    .data {
      display: flex;
      justify-content: space-between;
      padding: 6px;
      border-bottom: 1px solid #cdcdcd;

      h5 {
        font-size: 16px;
      }

      .numDisplay {
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
          width: 50%;
        }

        .num {
          background-color: $gray-500;
          border-radius: 100px;
          text-align: center;
          width: 1.5em;
          height: 1.5em;
          margin-right: 0.5em;
        }
      }

      h5 {
        margin: auto 0 !important;
      }

      .border {
        border: 1px solid #cdcdcd !important;
        margin-left: 1em;
      }
    }
  }
}

.next {
  background: #eeeeee !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #000 !important;
}

.saveButton {
  button {
    width: 100%;
    border: 1px solid #0022a0;
    background: #0022a0;
    color: #fff;
    padding: 0.3rem;
  }
}

.sideAccordian {
  .formData {
    margin-bottom: 10px;
  }

  .fw-medium {
    background-color: $lighterBlue !important;
    color: $white !important;
  }
}

.mapSection {
  border: 1px solid #b5b5b5;
  border-radius: 10px;

  .tab-content {
    padding: 0 !important;
  }

  .table-bordered {
    border: 1px solid #ced4da !important;

    th {
      color: $mainBlue;
    }
  }

  .nav-item {
    width: 50% !important;
    text-align: center;

    .active {
      background-color: $mainBlue !important;
      color: $white !important;
    }

    .nav-link {
      cursor: pointer;
      border-radius: 10px 10px 0px 0px !important;
      padding: 12px !important;
      background-color: $gray-400;
      color: $black;
    }
  }
}

.userProfileTable {
  margin-top: 2em;

  .table-bordered {
    border: 1px solid #f6f6f6 !important;
  }
}

.resumeBtn .btn {
  background-color: $mainColor !important;
  color: #fff;
}

.start {
  margin-left: 8em;
  padding: 4px 16px;
  font-size: 10px;
  height: 25px;
}

.table-bordered {
  border: 1px solid #f6f6f6 !important;
}

.accordion-header {
  display: flex !important;
  justify-content: space-between !important;
  // padding: 10px;

  .leftBtn {
    display: flex;
  }
}

.mapRegion {
  .accordion-header {
    background-color: $red_accordion;

    // button {
    //   background-color: #d2daff;
    //   padding: 1rem 5px;
    // }
    &.blue {
      background-color: $red_accordion;
    }

    &.area {
      color: $green_accordion;
    }

    &.territory {
      color: $green_accordion;
    }
  }

  .accordion-body {
    padding: 10px !important;
    background-color: #eef1fd;
  }

  .accordion-item {
    width: 100%;
  }

  .inner {
    margin-left: 0.8em;
    margin-top: 0.5em;

    .accordion-button {
      background-color: #ff96b2;
      padding: 5px 5px;
    }

    .accordion-body {
      background-color: #ffe0e8;
    }

    .saveBtns {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5em;
    }

    .editBtns {
      background-color: #fff !important;
      padding: 5px 5px;
    }
  }

  .addMap {
    border-radius: 100%;
    border: 1px solid $mainColor;
    width: 3em;
    height: 3em;
    padding: 14px;

    .fa {
      color: $mainColor;
    }
  }
}

.accordion-body {
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 5px !important;
  padding-left: 0 !important;
}

.sidefaq {
  padding: 1em 2em;
  margin-bottom: 0 !important;
}

.FromMap {
  padding: 1em;
}

.btsave {
  justify-content: center !important;
  color: $mainBlue;
  font-weight: 600;
  width: 26% !important;
  text-align: center;
  justify-content: center;
}

.divisionss {
  display: flex;
  justify-content: space-between;

  .Divisionhead {
    display: flex;

    img {
      width: 20px;
      height: 20px;
      margin-left: 1em;
    }
  }
}

.dropIcon .btn {
  padding-top: 10px;
}

.dragSection {
  display: flex;
  justify-content: space-between;
  background-color: #c2ccee;
  margin-bottom: 1em;
  border-radius: 10px;
  padding: 0.5em;

  .dragInput {
    background-color: #c2ccee;
    border: none;
  }

  h3 {
    margin-bottom: 0px;
  }

  .btn {
    .fas {
      color: red;
    }
  }
}

.addLevel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.dragSection input::placeholder {
  font-size: 18px !important;
  color: #000 !important;
}

.topDetail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.networkBtn {
  position: absolute !important;
  right: 0;
  bottom: -20px;
  width: 13em;
  text-align: center;
  background: #0022a0;
  justify-content: center;
  color: #fff;
}

.numb {
  // background-color: #fff;
  // padding: 5px 12px;
  border-radius: 20px;
  margin-top: -4px;
  margin-left: 4px;
  float: right;
  position: absolute;
}

.numb:hover .infoooo {
  display: block;
  position: absolute;
  top: -35%;
  width: 12em;
  left: 50%;
  right: -8%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 2px;
  text-align: center;
  z-index: 999999;
}

.infoooo {
  display: none;

  h5 {
    margin-bottom: 0;
  }
}

.mainInfo {
  position: relative;
}

.nmreport .mainInfo {
  text-align: center !important;
}

.wizard .steps > ul > li a {
  font-weight: 600 !important;
  padding: 1rem 0.5rem !important;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.75rem 0.5rem !important;
  font-size: 0.7875rem;
  border-radius: 0.2rem;
}

.mainText {
  .btn {
    padding: 0.55rem 0.85rem !important;
    background-color: $mainColor !important;
    border: 1px solid $mainColor !important;
  }

  .btn-danger {
    display: none;
  }
}

.nextMaster {
  background-color: $mainColor !important;
}

.nextMasterModal {
  background-color: $mainColor !important;
  color: white;
}

.comming {
  width: 70%;

  @media (max-width: 768px) {
    width: 100% !important;
    height: 100% !important;
  }
}

.cancelModal {
  background-color: #eeeeee;
  color: #343a40;
}

.mainSelect {
  .select2-selectionsssss__control {
    // padding: 0px 0 !important;
    padding: 0.25em 0 !important;
    // margin-top: 1em !important;
  }
}

.account-pages .select2-selectionsssss__control {
  // padding: 0px 0 !important;
  padding: 0.25em 0 !important;
  margin-top: 0em !important;
}

.divName {
  background-color: $gray-400;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.topHead {
  display: flex;

  img {
    width: 2em;
    height: 2em;
    margin-left: 1em;
    margin-top: -4px;
  }
}

.search {
  display: flex;

  .btn {
    padding: 0.75rem 0.85rem !important;
  }
}

// #chicago{
//   div{
//     height: 100% !important;
//     .map{
//       height: 100% !important;
//     }
//   }
// }
.formTable {
  position: relative;

  .zoom {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: $mainColor;
  }
}

.repeater {
  .saveBtn {
    .btn {
      border-radius: 100%;
      width: 2.5em;
      height: 2.5em;
      text-align: center;
      justify-content: center;
      padding: 10px;
      margin-top: 1em !important;
    }
  }
}

.account .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: $lightBlue !important;
}

.dataTable-hide-extra-props {
  .dataTables_wrapper {
    .row {
      justify-content: right !important;

      // [data-test="datatable-entries"] {
      //   display: none !important;
      // }
      // [data-test="datatable-search"] {
      //   display: none !important;
      // }
      // [data-test="datatable-info"] {
      //   display: none !important;
      // }
      @media only screen and (max-width: 768px) {
        [data-test="datatable-entries"] {
          width: 50% !important;

          .custom-select {
            margin-left: 0 !important;
          }
        }

        [data-test="datatable-search"] {
          width: 50% !important;
        }

        .dataTables_paginate .pagination {
          justify-content: center !important;
          margin-top: 1em !important;
        }
      }

      [data-test="table-foot"] {
        display: none !important;
      }

      [data-test="datatable-select"] {
        label {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.customeDatatable {
  margin-top: 1em;

  .dataTables_wrapper {
    .row {
      // justify-content: right !important;
      // [data-test="datatable-entries"] {
      //   display: none !important;
      // }
      // [data-test="datatable-search"] {
      //   display: none !important;
      // }
      // [data-test="datatable-info"] {
      //   display: none !important;
      // }
      @media only screen and (max-width: 768px) {
        [data-test="datatable-entries"] {
          width: 50% !important;

          .custom-select {
            margin-left: 0 !important;
          }
        }

        [data-test="datatable-search"] {
          width: 50% !important;
        }

        .dataTables_paginate .pagination {
          justify-content: center !important;
          margin-top: 1em !important;
        }
      }

      [data-test="table-foot"] {
        display: none !important;
      }

      [data-test="datatable-select"] {
        label {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.dataTable {
  .dataTables_wrapper {
    .row {
      // justify-content: right !important;
      // [data-test="datatable-entries"] {
      //   display: none !important;
      // }
      // [data-test="datatable-search"] {
      //   display: none !important;
      // }
      // [data-test="datatable-info"] {
      //   display: none !important;
      // }
      [data-test="table-foot"] {
        display: none !important;
      }

      @media only screen and (max-width: 768px) {
        [data-test="datatable-entries"] {
          width: 50% !important;

          .custom-select {
            margin-left: 0 !important;
          }
        }

        [data-test="datatable-search"] {
          width: 50% !important;
        }

        .dataTables_paginate .pagination {
          justify-content: center !important;
          margin-top: 1em !important;
        }
      }
    }
  }
}

.uploadTable {
  .dataTables_wrapper {
    .row {
      justify-content: right !important;

      // [data-test="datatable-entries"] {
      //   display: none !important;
      // }
      // [data-test="datatable-info"] {
      //   display: none !important;
      // }
      // [data-test="table-foot"] {
      //   display: none !important;
      // }
      @media only screen and (max-width: 768px) {
        [data-test="datatable-entries"] {
          width: 50% !important;

          .custom-select {
            margin-left: 0 !important;
          }
        }

        [data-test="datatable-search"] {
          width: 50% !important;
        }

        .dataTables_paginate .pagination {
          justify-content: center !important;
          margin-top: 1em !important;
        }
      }
    }
  }
}

.newbtn {
  .btn {
    background-color: $lightBlue;
    color: $white;
    margin: 2em 0;
  }
}

.adds {
  text-align: center;
}

.locationBtn {
  text-align: right;
  justify-content: right;
}

.changeO {
  background-color: $orange !important;
  border: $orange !important;
}

.year-picker {
  position: relative;
  outline: none;
  width: 100% !important;
  padding: 0.47rem 0.75rem !important;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  background-clip: padding-box;
  line-height: 1.5;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 1px solid transparent;
  border-color: #d9d9d9;
}

.picker-panel {
  position: absolute;
  top: 32px;
  left: 0 !important;
  width: 240px;
  height: 220px;
  top: 0 !important;
  z-index: 99999;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: white;
  transition: all 0.3s;

  .footer {
    padding: 0px calc(1.25rem / 2) !important;
  }
}

.Registerlogo {
  text-align: center;
  justify-content: center;
  padding: 1em 0;
}

.nav-item p {
  margin-bottom: 0 !important;
}

#topnav-menu-content svg {
  width: 14px;
  padding-bottom: 4px;
  margin-right: 7px;
}

.TabsName {
  position: relative;
}

.vertical-wizard .TabsName {
  color: #000;
}

.numb .fas {
  font-size: 8px;
}

.numb img {
  height: 10px;
}

.topnav .navbar-nav > .dropdown.active > a {
  color: #000 !important;
}

#topnav-menu-content a {
  color: #000 !important;
}

.user-right-form {
  // border: 2px solid red;
}

.accordion-user-form {
  // border: 2px solid blue;
}
.accordion-custom-header {
  background-color: #edf0ff;
}
.accordion-custom-header button {
  width: 100%;
  border: none !important;
  background: #dbdbdb !important;
  border-radius: 5px 5px 0px 0px !important;
}

.accordion-custom-header .accordion-title {
  font-weight: 500;
  color: #0022a0;
  font-size: 17px;
  text-align: left;
  margin: 0;
  padding: 0.8rem;
  background-color: #edf0ff;
}

.custom-accordion-body {
  padding: 0 0.5rem;
  background-color: #f5f5f5 !important;
  border-radius: 0px 0px 5px 5px !important;
}

.nested-accordion-header button {
  border: none !important;
  background-color: #fff !important;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 8px !important;
}

.nested-accordion-header .accordion-title {
  font-weight: 500;
  color: #000;
  font-size: 14px;
  text-align: left;
  margin: 0;
  padding: 0.8rem;
}

.customized-form-group {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-left: 0;
  margin: 0;
}

.userinput-form-group {
  margin: 0;
  padding-left: 25px;
}

.userinput-form-group label {
  margin-right: 2px;
  font-size: 14px;
  font-weight: 500;
}

.catalog-header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.catalog-header-box img {
  height: 4em;
  object-fit: contain;
}

.new-list-decor li {
  list-style: none !important;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0 !important;
  border-bottom: 1px solid #efeff2;
}

.permission-module-container {
  background: #0000001a;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  border-radius: 6px !important;
}

.permission-checkbox-container {
  display: flex;
  flex-direction: row-reverse;
  margin: 0px;
  padding: 5px;
}

.permission-checkbox-container:nth-child(even) {
  background-color: transparent;
}

.permission-checkbox-container:nth-child(odd) {
  background-color: transparent;
}

.permission-checkbox-label {
  order: 2;
}

.permission-checkbox-input {
  order: 1;
  margin-left: 10px !important;
}

@media (max-width: 767px) {
  .catalog-header-box h3 {
    font-size: larger !important;
  }

  .catalog-header-box img {
    height: 3em;
  }

  .catalog-header-box {
    align-items: baseline !important;
  }
}

.webFilter {
  display: block;
}

.mobFilter {
  display: none;
}

.mobFilters {
  display: none !important;
}

.redirect p {
  margin-bottom: 0;
  padding: 1em 0;
  border-bottom: 1px solid #e7e7e7;
}

.extra {
  .page-content {
    padding: 0 !important;
    margin-top: 0 !important;
  }

  .tab-content {
    margin-top: 0em !important;
  }

  .text-muted {
    margin-top: 1em !important;
  }
}

.profile {
  h3 {
    margin-top: 0 !important;
    color: #000 !important;
  }
}

.customized-form-group {
  align-items: center !important;
}

.mainFormSubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
}

.currentReport a {
  background-color: #0022a0 !important;
  color: white !important;
  text-align: center;
}

.bin {
  text-align: center;

  img {
    width: 20%;
  }
}

.delete-btn {
  background-color: #ff5a5a !important;
}

.cancel-btn {
  background-color: #6969d5 !important;
  color: #fff !important;
}

.hide-bullet-catalog li {
  .fas {
    color: #dc640d;
  }
  .far {
    color: #dc640d;
  }

  strong {
    margin-bottom: 0.2;
  }

  padding-left: 1em !important;

  .mt-1 {
    li {
      list-style: none;
      margin-left: 1em;
      position: relative;
      padding-left: 0.5em;
    }

    ::before {
      content: "";
      background-color: #dc640d;
      width: 6px;
      height: 6px;
      position: absolute;
      left: -14px;
      top: 8px;
      border-radius: 100px;
    }
  }

  list-style: none;
}

.head-catalog {
  // padding-left: 2em;
  font-size: 16px;
}

.head-network-engage {
  // padding-left: 2em;
  font-size: 16px;
}

.btn-css {
  text-align: end;

  .tempText {
    p {
      text-align: left !important;
    }
  }
}

.network-engage {
  margin-bottom: 1em !important;
}

.otp-invalid {
  color: #f46a6a !important;
  font-size: 10px !important;
  margin-top: 5px;
}

#email-editor-container .blockbuilder-branding {
  display: none !important;

  span {
    display: none !important;
  }

  i {
    display: none !important;
  }
}

.magic_image_panel {
  display: none !important;
}

.error-input {
  height: 40px;
  width: 100%;
}

.scrollable-list {
  max-height: 40px; /* Set a max height for the scrollbar */
  overflow-y: hidden; /* Enable vertical scrolling */
  overflow-x: hidden;
}

.searchBtn {
  display: none !important;
}

// #react-select-2-listbox {
//   position: relative !important;
//   z-index: 89788 !important;
// }

.css-1nmdiq5-menu {
  position: relative !important;
  z-index: 89788 !important;
}

.fourzeros-section {
  img {
    width: 50%;
  }
  text-align: center;
  .four-content {
    text-align: center;
    justify-content: center;
    margin-top: 1em !important;
    h2 {
      font-weight: 600;
      margin-top: 0.5em;
    }
    p:nth-child(2) {
      margin-bottom: 0 !important;
    }
    .zero-btn {
      margin-bottom: 0.5em;
    }
  }
}

.campaign-accordian {
  padding: 0em 0em 1.5em 1em;
}

.campiagn-section {
  .accordion-button {
    box-shadow: none !important;
  }
}

.react-datepicker__input-container input {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}
